<template>
  <el-scrollbar style="height:100%;">
    <div class="homeBox">
      <div class="home" style="min-height:100vh;">
        <div class="head">
          <div class="background"></div>
          <ul class="nav">
            <li :class="webType===items.val?'active':''" @click="handlerNav(items.val)" v-for="(items, index) in nav"
              :key="index">
              {{items.name}} <div class="bg"></div>
            </li>
          </ul>
          <div class="name"><img :src="loginInfo.PlatformLogo"><h3 v-if="loginInfo.PlatformTitle">{{loginInfo.PlatformTitle}}</h3></div>
<!--           
          <div class="menu" @mouseenter="enter" @mouseleave="leave">
            <div class="inner">{{$t('route.home')}} <span></span></div>
            <transition name="default">
              <ul v-if="dropMenu">
                <li v-for="(item,index) in FunMenu" :key="index">
                  <router-link :to='item.link' tag="span">{{item.title}}</router-link>
                </li>
              </ul>
            </transition>
          </div>  -->
          <div class="menu">
            <div class="inner">
              <router-link to='/online/monitor' tag="span">进入工作台</router-link>
            </div>
          </div>
          <div class="exits" @click="handlerExit">
            <i-icon name="icontuichu"></i-icon><span>{{$t('route.exit')}}</span>
          </div>
        </div>
        <div class="homeContent">
          <div class="row">
            <div class="colLeft">
              <div class="top">
                <div class="title">
                  <span>{{$t('home.titleOnline')}}</span>
                </div>
                <div class="box">
                  <i class="lt"></i>
                  <i class="lb"></i>
                  <i class="rt"></i>
                  <i class="rb"></i>
                </div>
                <div class="in">
                  <div class="chart" id="chart_device"></div>
                  <ul class="chart_legend">
                    <li v-for="(cell,index) in chart_device_data" :key="index">
                      <i :class="(cell.item==='在线'||cell.item==='Online')?'on':'off'"></i>
                      <p>
                        <strong>
                          <animated-integar :value="cell.count"></animated-integar>
                        </strong>
                        <em>{{cell.item}} </em>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="bottom">
                <div class="title">
                  <span>{{$t('home.titleDevice')}}</span>
                </div>
                <div class="box">
                  <i class="lt"></i>
                  <i class="lb"></i>
                  <i class="rt"></i>
                  <i class="rb"></i>
                </div>
                <div class="in">
                  <div class="container" ref="container"></div>
                  <div class="left2m">
                    <p>{{$t('home.titleNet')}}:</p>
                    <label>
                      <animated-integar :value="spred_total"></animated-integar>
                    </label>
                  </div>
                  <div class="maploading" v-if="mapLoading">
                    <circle2 size="24px"></circle2>
                  </div>
                </div>
              </div>
            </div>
            <div class="colMid">
              <div class="video">
                <video autoplay loop muted v-show="webType===0"><source src="https://www.365qczx.com/video/ps1.mp4" type="video/mp4" /></video>
                <video autoplay loop muted v-show="webType===1"><source src="https://www.365qczx.com/video/ps2.mp4" type="video/mp4" /></video>
                <video autoplay loop muted v-show="webType===2"><source src="https://www.365qczx.com/video/ps3.mp4" type="video/mp4" /></video>
                 <!-- <video autoplay loop muted v-show="webType===0"><source :src="videoUrl" type="video/mp4" /></video> -->
              </div>
              <div class="in">
                <div class="title">
                  <span>{{$t('home.titleTotal')}}</span>
                </div>
                <div class="box">
                  <i class="lt"></i>
                  <i class="lb"></i>
                  <i class="rt"></i>
                  <i class="rb"></i>
                </div>
                <div class="cen">
                  <div class="onlineTotal">
                    <!-- <div class="chart" id="chart_total"></div>
                    <div class="chartBg"><img :src="require('../../assets/images/homeChart.png')" alt=""></div> -->
                  </div>
                  <div class="onlineWeek">
                    <div class="chart" id="chart_Circle"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="colRight">
              <div class="top">
                <div class="title">
                  <span>{{$t('home.titleNew')}}</span>
                </div>
                <div class="box">
                  <i class="lt"></i>
                  <i class="lb"></i>
                  <i class="rt"></i>
                  <i class="rb"></i>
                </div>
                <div class="chart_online">
                  <div class="chart" id="chart_online"></div>
                </div>
              </div>
              <div class="mid">
                <div class="title">
                  <span>{{$t('home.titleYear')}}</span>
                </div>
                <div class="box">
                  <i class="lt"></i>
                  <i class="lb"></i>
                  <i class="rt"></i>
                  <i class="rb"></i>
                </div>
                <div class="chart_three">
                  <div class="chart" id="chart_three_years"></div>
                </div>
              </div>
              <div class="bottom">
                <div class="title">
                  <span>{{$t('home.titleEven')}}</span>
                </div>
                <div class="box">
                  <i class="lt"></i>
                  <i class="lb"></i>
                  <i class="rt"></i>
                  <i class="rb"></i>
                </div>
                <div class="in">
                  <div class="scrollbar" ref="scroll" @mouseenter="pauseScroll" @mouseleave="continueScroll">
                    <ul>
                      <li v-for="(i, index) in recentAlarm" :key="index" class="line">
                        <span class="alarmname">{{$t('home')[i.AlarmType]}}</span>
                        <p><span class="alarmtime">{{miment(i.RcvTime).format()}}</span>
                          <span :title="'设备号：' + i.SIM2" @click="goMonitor(i.ObjectID)">{{i.SIM2}}</span>
                        </p>
                        <span class="sim2" :title="$t('home.copy')" @click="copyText(i.SIM2)"><i
                            class="el-icon-document" />
                          <span style="display:none">{{i.SIM2}}</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <span class="nodata" v-if="!recentAlarm.length"><i
                      class="el-icon-warning" />{{$t('home.noDatas')}}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="homeFooter">
          <div class="cen">
            <div class="title">
              <span>{{$t('home.titleAlarm')}}</span>
            </div>
            <div class="box">
              <i class="lt"></i>
              <i class="lb"></i>
              <i class="rt"></i>
              <i class="rb"></i>
            </div>
            <template v-if="webType===1">
              <ul class="in">
                <li v-for="(item, index) in alarmList.person" :key="index" @click="openAlarm(item.name,1)">
                  <div class="img"><img :src="require(`../../assets/images/${item.icon}.png`)" alt=""></div>
                  <div class="text">
                    <h5>
                      <animated-integar :value="item.count"></animated-integar>
                    </h5>
                    <p>{{$t('home')[item.name]}}</p>
                  </div>
                </li>
              </ul>
            </template>
            <template v-if="webType===2">
              <ul class="in">
                <li v-for="(item, index) in alarmList.object" :key="index" @click="openAlarm(item.name,2)">
                  <div class="img"><img :src="require(`../../assets/images/${item.icon}.png`)" alt=""></div>
                  <div class="text">
                    <h5>
                      <animated-integar :value="item.count"></animated-integar>
                    </h5>
                    <p>{{$t('home')[item.name]}}</p>
                  </div>
                </li>
              </ul>
            </template>
            <template v-if="webType===0">
              <ul class="in">
                <li v-for="(item, index) in alarmList.vehicle" :key="index" @click="openAlarm(item.name,0)">
                  <div class="img"><img :src="require(`../../assets/images/${item.icon}.png`)" alt=""></div>
                  <div class="text">
                    <h5>
                      <animated-integar :value="item.count"></animated-integar>
                    </h5>
                    <p>{{$t('home')[item.name]}}</p>
                  </div>
                </li>
              </ul>
            </template>
          </div>
        </div>


      </div>
      <alarm ref="alarm"></alarm>
    </div>
  </el-scrollbar>
</template>

<script>
  import * as G2 from '@antv/g2';
  import AnimatedIntegar from '@/components/animated-integar'
  import {
    remoteLoad,
    debounce
  } from '@/common/utils'
   import {
    setAutoLogin,
    setUserRole,
    getOutUrl,
  } from "@/common/cache";
   import {
    removeToken
  } from "@/common/auth";
  import * as API from '@/api/home'
  import {
    AMAP_KEY
  } from '@/common/config'
  import {
    QueryAllSpread,
    QueryNowSpread
  } from '@/api/home'
  // import SvgText from '@/components/svg-text'
  import miment from 'miment'
  import BScroll from 'better-scroll'
  import {
    mapGetters,
    mapActions,
  } from "vuex";
  import {
    Circle as Circle2
  } from 'vue-loading-spinner'
  import Alarm from './alarm/alarm'
  // const Alarm = require('./alarm/alarm' + process.env.VUE_APP_VRC + '.vue').default
  export default {
    data() {
      return {
        miment,
        nav: [{
            name: this.$t('home.deviceStatus1'),
            val: 0
          },
          {
            name: this.$t('home.deviceStatus2'),
            val: 1
          },
          {
            name: this.$t('home.deviceStatus3'),
            val: 2
          }
        ],
        dropMenu: false,
        webType: 0,
        oneYear: [],
        spred_data: [],
        spred_total: 0,
        onlineData: [{
          value: '',
          type: '-',
          percent: ''
        }, {
          value: '',
          type: '-',
          percent: ''
        }],
        offlineData: [{
          value: '',
          type: '-',
          percent: ''
        }, {
          value: '',
          type: '-',
          percent: ''
        }],
        tabRangeName: '近7天',
        alarmList: {
          vehicle: [{
              count: 0,
              name: "低电压报警",
              icon: 'icon_01'
            },
            {
              count: 0,
              name: "低电量报警",
              icon: 'icon_02'
            },
            {
              count: 0,
              name: "拆机报警",
              icon: 'icon_03'
            },
            {
              count: 0,
              name: "断电报警",
              icon: 'icon_04'
            },
            {
              count: 0,
              name: "区域报警",
              icon: 'icon_05'
            },
            {
              count: 0,
              name: "围栏报警",
              icon: 'icon_06'
            },
          ],
          object: [{
              count: 0,
              name: "低电量报警",
              icon: 'icon_02'
            },
            {
              count: 0,
              name: "围栏报警",
              icon: 'icon_06'
            },
            {
              count: 0,
              name: "偏离路线报警",
              icon: 'icon_10'
            },
            {
              count: 0,
              name: "跌落报警",
              icon: 'icon_09'
            },
            {
              count: 0,
              name: "碰撞报警",
              icon: 'icon_08'
            }
          ],
          person: [{
              count: 0,
              name: "紧急报警",
              icon: 'icon_07'
            },
            {
              count: 0,
              name: "低电量报警",
              icon: 'icon_02'
            },
            {
              count: 0,
              name: "围栏报警",
              icon: 'icon_06'
            },
            {
              count: 0,
              name: "偏离路线报警",
              icon: 'icon_10'
            },
            {
              count: 0,
              name: "跌落报警",
              icon: 'icon_09'
            }
          ],
        },
        wono: [0, 0, 0, 0, 0],
        fkList: {
          gaowei: 0,
          yujing: 0,
          zhangchang: 0
        },
        recentAlarm: [],
        threeYear: [],
        queryTime: null,
        mapLoading: false,
        plat: process.env.VUE_APP_PLAT,
        minHeight: 800,
        deviceSum: '',
        chart_online: null,
        chart_three_years: null,
        chart_total: null,
        chart_Circle: null,
        chart_device: null,
        chart_device_data: {

        },
        layer: null,
        FunMenu: []
      }
    },
    components: {
      Alarm,
      Circle2,
      AnimatedIntegar,
      // SvgText
    },
    computed: {
      totalOnline() {
        return this.sumArr(this.onlineData.map(k => k.value))
      },
      totalOffline() {
        return this.sumArr(this.offlineData.map(k => k.value))
      },
      ...mapGetters(["menuPosition", 'loginInfo'])
    },
    beforeDestroy() {
      clearInterval(window._homed_interval)
      window.removeEventListener('resize', this.d_resize)
    },
    methods: {
      enter: function () {
        this.dropMenu = true
      },
      leave: function () {
        this.dropMenu = false
      },
      handlerNav(type) {
        this.webType = type
        this.videoUrl = 'http://192.168.0.72:8989/ps' + type + '.mp4'
        this.fetchOneYear(type).then(res => {
          this.chart_online.data(res)
          this.chart_online.render(res)
        })
        this.fetchThreeYear(type).then(res => {
          this.chart_three_years.data(res)
          this.chart_three_years.render(res)
        })
        this.fetchTotal(type).then(res => {
          let total = 0
          let obj = res
          if (obj.length > 0) {
            obj.forEach(k => total += k.sales)
            obj.forEach(k => k.percent = Math.round(k.sales / total * 100) / 100.00)
            // obj.forEach(k => k.percent = Math.ceil(k.sales / total * 100) / 100)
          }
          // this.chart_total.data(res)
          // this.chart_total.render(res)
          this.chart_Circle.data(obj)
          this.chart_Circle.render(obj)
        })
        this.fetchDeviceIsOnline(type).then(res => {

          // this.chart_device.annotation().clear()
          // this.chart_device
          // .annotation()
          // .text({
          //   position: ['50%', '50%'],
          //   content: type,
          //   style: {
          //     fontSize: 20,
          //     fill: '#fff',
          //     textAlign: 'center',
          //   },
          //   offsetY: -10,
          // })
          // .text({
          //   position: ['50%', '50%'],
          //   content: '设备总数',
          //   style: {
          //     fontSize: 12,
          //     fill: '#fff',
          //     textAlign: 'center',
          //   },
          //   offsetY: 15,
          // });
          // console.log(this.chart_device)

          this.chart_device.data(res)
          this.chart_device.render(res)
        })
        this.fetchHomeAlarm(type)

        this.getData(type).then(() => {
          this.layer.setData(this.spred_data, {
            type: 'json',
            lnglat: 'center'
          })
          this.layer.render()
        })
        this.refreshData(type)
      },
      pauseScroll() {
        clearTimeout(window._homed_timer)
      },
      continueScroll() {
        clearTimeout(window._homed_timer)
        window._homed_timer = setTimeout(this.scrollNextPage, 2000)
      },
      scrollNextPage() {
        if (this.bScroll.y > this.bScroll.maxScrollY) {
          this.bScroll.scrollBy(0, -38, 1000)
          clearTimeout(window._homed_timer)
          window._homed_timer = setTimeout(this.scrollNextPage, 2000)
        } else {
          this.bScroll.scrollTo(0, 0, 0)
          clearTimeout(window._homed_timer)
          window._homed_timer = setTimeout(this.scrollNextPage, 2000)
        }
      },
      initScroll() {
        if (!this.bScroll) {
          setTimeout(() => {
            this.bScroll = new BScroll(this.$refs.scroll, {
              scrollY: true,
              scrollX: false,
              click: true,
              scrollbar: false,
              mouseWheel: true
            })
            clearTimeout(window._homed_timer)
            window._homed_timer = setTimeout(this.scrollNextPage, 2000)
          }, 50)
        } else {
          this.bScroll.scrollTo(0, 0, 0)
          clearTimeout(window._homed_timer)
          window._homed_timer = setTimeout(this.scrollNextPage, 2000)
        }
      },
      async initMap() {
        return new Promise((resolve, reject) => {
          let createMap = () => {
            this.map = new window.AMap.Map(this.$refs.container, {
              center: [106.484242, 36.996427],
              features: ['bg', 'road'],
              mapStyle: 'amap://styles/midnight',
              rotation: 0,
              zoom: 3,
              // viewMode: '3D',
              pitch: 0,
              skyColor: '#33216a'
            });
            resolve()
          }
          let errorHandle = err => reject(err)
          if (!window.AMap) {
            remoteLoad(this.$AMAP_URL).then(() => {
              setTimeout(createMap, 300)
            }).catch(errorHandle)
          } else {
            createMap()
          }
        })
      },
      initLoca() {
        return new Promise((resolve, reject) => {
          if (!window.Loca) {
            remoteLoad(`//webapi.amap.com/loca?v=1.3.2&key=${AMAP_KEY}`).then(() => {
              resolve()
            }).catch(err => {
              reject(err)
            })
          } else {
            resolve()
          }
        })
      },
      getData(type) {
        return new Promise((resolve, reject) => {
          QueryAllSpread(type).then(ret => {
            this.spred_data = ret.data.list
            this.spred_total = ret.data.total
            this.queryTime = ret.data.time
            resolve()
          }).catch(err => {
            reject(err)
          })
        })
      },
      openAlarm(name, type) {
        this.$refs.alarm.open(name, type)
      },
      fetchData(type) {
        this.fetchDeviceIsOnline(type).then(res => {
          this.initChartDevice(res)
        })
        this.fetchOneYear(type).then(res => {
          this.loadChart_new(res)
        })
        this.fetchThreeYear(type).then(res => {
          this.initChartThreeYears(res)
        })
        this.fetchTotal(type).then(res => {
          let total = 0
          let obj = res
          if (obj.length > 0) {
            obj.forEach(k => total += k.sales)
            obj.forEach(k => k.percent = Math.round(k.sales / total * 100) / 100.00)
            // obj.forEach(k => k.percent = Math.ceil(k.sales / total * 100) / 100 )
          }
          // this.initChartTotal(res)
          this.initChartCircle(obj)
        })
        this.fetchHomeAlarm(type)

        API.QueryRecentAlarm(type).then(ret => {
          this.recentAlarm = ret.data
          this.initScroll()
        }).catch(() => {

        })
        clearInterval(window._homed_interval)
        window._homed_interval = setInterval(this.refreshData, 5 * 60 * 1000)
      },
      refreshData() {
        API.QueryRecentAlarm(this.webType).then(ret => {
          this.recentAlarm = ret.data
          this.initScroll()
        }).catch(() => {

        })
      },
      async init() {
        this.mapLoading = true
        await this.initMap()
        let promise1 = this.getData(0)
        let promise2 = this.initLoca()
        Promise.all([promise1, promise2]).then(() => {
          this.$loadingc().close()
          this.layer = new window.Loca.RoundPointLayer({
            map: this.map
          });

          this.layer.setData(this.spred_data, {
            type: 'json',
            lnglat: 'center'
          })
          this.layer.setOptions({
            unit: 'px',
            style: {
              radius() {
                return 1.5
              },
              height: 0,
              color() {
                return '#FFEEB1'
              }
            }
          })
          this.layer.render()
          setTimeout(() => {
            this.rendered = true
          }, 100)

        }).catch(err => {
          console.error(err)
          this.$message({
            message: '数据加载失败',
            type: 'error'
          })
        }).finally(() => {
          this.mapLoading = false
        })
      },
      loadChart_new(data) {
        // Step 1: 创建 Chart 对象
        this.chart_online = new G2.Chart({
          container: 'chart_online', // 指定图表容器 ID
          autoFit: true,
          width: 520, // 指定图表宽度
          height: 180, // 指定图表高度
          padding: [20, 40, 20, 40]
        });
        // Step 2: 载入数据源
        this.chart_online.source(data);
        this.chart_online.legend(false)
        this.chart_online.axis('year', {
          line: {
            lineWidth: 2,
            stroke: '#FFF',
            color: '#FFF',
          },
          label: {
            style: {
              fill: '#fff',
            },
          },
          grid: null
        })
        this.chart_online.axis('sales', {
          label: {
            style: {
              fill: '#fff',
            },
          },
          line: {
            lineWidth: 1,
            stroke: '#FFF'
          },
        })
        this.chart_online.scale('sales', {
          alias: this.$t('home.count')
        });

        this.chart_online.line().position('year*sales').style({
          lineWidth: 1,
          stroke: '#15dbea'
        }).shape('smooth')
        this.chart_online.area().position('year*sales').color("l(90) 0:#10ecfd 1:#152027")
          .shape('smooth').tooltip(false)
        this.chart_online.point().position('year*sales')
        this.chart_online.render();
      },
      initChartThreeYears(data) {
        this.chart_three_years = new G2.Chart({
          container: "chart_three_years",
          autoFit: true,
          width: 520,
          height: 120,
          padding: [20, 40, 20, 40]
        });
        this.chart_three_years.data(data);
        this.chart_three_years.axis('item', {
          label: {
            style: {
              fill: '#fff',
            },
          }
        })
        this.chart_three_years.axis('count', {
          label: {
            style: {
              fill: '#fff',
            },
          },
          grid: null
        })
        this.chart_three_years.scale('count', {
          alias: this.$t('home.count')
        });

        this.chart_three_years.tooltip({
          showMarkers: false,
        });

        this.chart_three_years.interval().position('item*count').size(24).color("l(0) 0:#0a3557  1:#054c56").style({
          // fillOpacity: 1,
          lineWidth: 1,
          stroke: '#16ddfd'
        }).label('count', {
          style: {
            fill: '#fff',
          },
          offset: 10,
        });

        ;
        this.chart_three_years.interaction('active-region');
        this.chart_three_years.render();
      },
      initChartTotal(data) {
        this.chart_total = new G2.Chart({
          container: "chart_total",
          autoFit: true,
          width: 780,
          height: 360,
          padding: [60, 20, 20, 60]
        });
        this.chart_total.data(data);
        this.chart_total.tooltip({
          showMarkers: false,
        });

        this.chart_total.axis('count', {
          label: {
            style: {
              fill: '#fff',
            },
          },
          grid: null
        })
        this.chart_total.axis('item', {
          label: {
            // rotate:170,
            // autoRotate:true,
            autoHide: false,
            autoEllipsis: true,
            style: {
              fill: '#fff',
            },
          },
          grid: null
        })
        this.chart_total.scale('count', {
          alias: this.$t('home.count')
        });
        this.chart_total.line().position('item*count').style({
          lineWidth: 1,
          stroke: '#fff'
        })
        this.chart_total.point().position('item*count');

        this.chart_total.interval().position('item*count').size(30).color('l(90) 0:#00f1ff 1:#1891ff').style({
          fillOpacity: 1
        }).label('count', {
          style: {
            fill: '#fff',
          },
          offset: 10,
        });
        this.chart_total.interaction('active-region');
        this.chart_total.render();
      },

      initChartCircle(data) {
        this.chart_Circle = new G2.Chart({
          container: "chart_Circle",
          autoFit: true,
          width: 640,
          height: 260,
          padding: [40, 200, 20, 0]
        });
        this.chart_Circle.data(data);
        this.chart_Circle.scale('percent', {
          formatter: (val) => {
            val = val * 100 + '%';
            return val;
          },
        });
        this.chart_Circle.legend({
          position: 'right',
          offsetX: this.$i18n.locale === 'zh' ? -140 : -30,
          marker: (name, index, item) => {
            return {
              symbol: 'square',
              style: {
                fontSize: 30
              },
            };
          },
          itemName: {
            style: {
              fill: '#fff',
              fontSize: 15
            },
          },
        });

        this.chart_Circle.coordinate('theta', {
          radius: 0.75,
          innerRadius: 0.6,
        });
        this.chart_Circle.tooltip({
          showTitle: false,
          showMarkers: false,
          itemTpl: '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
        });
        this.chart_Circle
          .interval()
          .adjust('stack')
          .position('percent')
          .color('item', ['#10a8bc', '#ffa514', '#15dba2', '#1890ff', '#ff7644', '#745fd6'])
          .label('percent', (percent) => {
            return {
              style: {
                fill: '#fff'
              },
              content: (data) => {
                return `${data.item}: ${data.percent * 1000/10}%`;
              },
            };
          })
          .tooltip('item*percent', (item, percent) => {
            percent = percent * 1000 / 10 + '%';
            return {
              name: item,
              value: percent,
            };
          });

        this.chart_Circle.interaction('element-active');

        this.chart_Circle.render();
      },
      initChartDevice(data) {
        let total = 0
        data.forEach(k => total += k.count)
        this.chart_device = new G2.Chart({
          container: "chart_device",
          autoFit: true,
          width: 320,
          height: 180,
          padding: [0, 0, 0, 0]
        });
        this.chart_device.data(data);
        this.chart_device.scale('percent', {
          formatter: (val) => {
            val = val * 1000 / 10 + '%';
            return val;
          },
        });
        // this.chart_device.legend({
        //   position: 'right',
        //   offsetX: -80,
        //    marker: (name, index, item) => {
        //     return {
        //       symbol: 'square',
        //       style: {
        //         fontSize: 30
        //       },
        //     };
        //   },
        //   itemName:{
        //     style: {
        //       fill: '#fff',
        //       fontSize: 15
        //     },
        //     formatter: (item,data) => {
        //       console.log(data)
        //       return item + data;
        //     },
        //   },

        // });
        this.chart_device.legend(false);
        this.chart_device.coordinate('theta', {
          radius: 0.75,
          innerRadius: 0.6,
        });
        this.chart_device.tooltip({
          showTitle: false,
          showMarkers: false,
          itemTpl: '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
        });
        // 辅助文本
        this.chart_device
          .annotation()
          .text({
            position: ['50%', '50%'],
            content: (data) => {
              return `${data[0].count + data[1].count}`;
            },
            animate: false,
            // total,
            style: {
              fontSize: 20,
              fill: '#fff',
              textAlign: 'center',
            },
            offsetY: -10,
          })
          .text({
            position: ['50%', '50%'],
            content: this.$t('home.chartOnline.total'),
            style: {
              fontSize: 12,
              fill: '#fff',
              textAlign: 'center',
            },
            offsetY: 15,
          });
        this.chart_device
          .interval()
          .adjust('stack')
          .position('percent')
          .color('item', ['#1890ff', '#97b3cc'])
          .label('percent', (percent) => {
            return {
              style: {
                fill: '#fff'
              },
              content: (data) => {
                return `${data.item}: ${data.percent*100/100}%`;
              },
            };
          })
          .tooltip('item*percent', (item, percent) => {
            percent = percent * 100 / 100 + '%';
            return {
              name: item,
              value: percent,
            };
          });
        this.chart_device.interaction('element-active');
        this.chart_device.render();
      },
      goMonitor(oid) {
        this.$router.push(`/online/monitor?action=addTaskList&objectid=${oid}`)
      },
      copyText(text) {
        var el = document.createElement('input')
        el.value = text
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        this.$message({
          message: this.$t('home.msgTips'),
          type: 'success'
        })
      },
      fetchDeviceIsOnline(type) {
        const promise = new Promise((resolve, reject) => {
          API.GetDeviceIsOnline(type).then(ret => {
            let on = ret.data.wireOnline + ret.data.wirelessOnline
            let off = ret.data.wireOffline + ret.data.wirelessOffline
            let total = on + off
            let obj = [{
                item: this.$t('home.chartOnline.s1'),
                count: on,
                percent: total !== 0 ? ((Math.ceil(on / total * 1000) / 1000).toFixed(2) * 100) : 0
              },
              {
                item: this.$t('home.chartOnline.s2'),
                count: off,
                percent: total !== 0 ? ((Math.ceil(off / total * 1000) / 1000).toFixed(2) * 100) : 0
              }
            ]
            this.chart_device_data = Object.assign({}, obj)
            resolve(obj)
          }).catch(err => {
            reject(err)
          }).finally(() => {})
        })
        return promise
      },
      fetchOneYear(type) {
        const promise = new Promise((resolve, reject) => {
          API.GetHome_OneYear(type).then(ret => {
            let oneYear = ret.data.map(k => {
              return {
                year: k.name,
                name: k.name,
                index: k.index,
                sales: k.count
              }
            })
            this.oneYear = oneYear
            resolve(oneYear)
          }).catch(err => {
            reject(err)
          }).finally(() => {
            // this.listLoading = false
          })
        })
        return promise
      },
      fetchThreeYear(type) {
        const promise = new Promise((resolve, reject) => {
          API.GetHome_ThreeYear(type).then(ret => {
            let yearData = ret.data.map(k => {
              return {
                year: k.name.replace('年', ''),
                name: k.name.replace('年', ''),
                index: k.index,
                sales: k.count
              }
            }).splice(0, 3)
            let total = 0
            if (yearData.length > 0) {
              yearData.forEach(k => total += k.sales)
              yearData.forEach(k => k.percent = Math.ceil(k.sales / total * 1000) / 10)
            }
            let threeYearObj = {}
            yearData.forEach(k => {
              threeYearObj[k.name] = k.sales
            })
            let data = yearData.map(k => {
              return {
                item: k.name,
                count: k.sales,
                percent: k.percent ? k.percent : 0
              }
            })
            resolve(data)
          }).catch(err => {
            reject(err)
          }).finally(() => {
            // this.listLoading = false
          })
        })
        return promise
      },
      fetchTotal(type) {
        const promise = new Promise((resolve, reject) => {
          API.GetHome_OnlineRate(type).then(ret => {
            let threeYear = ret.data.map(k => {
              return {
                count: k.count,
                item: this.$t('home.chart2')[k.name],
                sales: k.count
              }
            })
            resolve(threeYear)
          }).catch(err => {
            reject(err)
          }).finally(() => {
            // this.listLoading = false
          })
        })
        return promise
      },
      fetchHomeAlarm(type) {
        const promise = new Promise((resolve, reject) => {
          API.GetHome_Alarm_af(type).then(ret => {
            let list = ret.data.alarm
            if (this.webType === 0) {
              this.alarmList.vehicle.forEach((item) => {
                list.forEach((k) => {
                  if (item.name === k.name) {
                    item.count = k.count
                  }
                })
              })
            } else if (this.webType === 1) {
              this.alarmList.person.forEach((item) => {
                list.forEach((k) => {
                  if (item.name === k.name) {
                    item.count = k.count
                  }
                })
              })
            } else {
              this.alarmList.object.forEach((item) => {
                list.forEach((k) => {
                  if (item.name === k.name) {
                    item.count = k.count
                  }
                })
              })
            }
            resolve()
          }).catch(err => {
            reject(err)
          }).finally(() => {})
        })
        return promise
      },
      handlerExit() {
          let msg0 ="确定要退出系统？"
        this.$confirm(msg0, this.$t('common.warn'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          this.exit()
        })
      },
      exit() {
        setAutoLogin({
          u: '',
          p: '',
          isAuto: false,
          isAgree: true,
        });
        removeToken();
        setUserRole();
        this.delAllViews().then(() => {
          const outUrl = getOutUrl();
          if (outUrl) {
            window.location.href = outUrl;
          } else {
            this.$router.push("/login");
          }
        });
      },
       ...mapActions([
        "delAllViews",
      ]),
    },
    created() {
      this.d_resize = debounce(() => {
        this.minHeight = (window.innerWidth * .4 * .5) + 600;
        this.minHeight = this.minHeight < 800 ? 800 : this.minHeight;
      });

      window.addEventListener('resize', this.d_resize)
      this.d_resize()
    },
    mounted() {
      this.init()
      this.fetchData(0)
    }
  }

</script>

<style lang="scss" scoped>
  @import "./home.scss";

  .el-scrollbar {
    & ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden !important;
      overflow-y: auto;

      .el-scrollbar__view {
        min-height: 100vh;

        .homeBox {
          min-height: 100%;
        }
      }
    }
  }

</style>
